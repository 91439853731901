import { Navbar } from '../components/Navbar'
import { Banner } from '../components/Banner'
import { Footer } from '../components/Footer'
import { graphql } from 'gatsby'
import { ResponsiveView } from '../components/ResponsiveView'
import { Work } from '../components/Work'

const IndexPage = ({ location, data }) => {
  const projects = data.allProjectsJson.edges.map((p) => p.node)
  const work = data.allWorkJson.edges.map((p) => p.node)

  return (
    <main className='flex flex-col '>
      <Navbar location={location} />
      <section className='min-h-screen'>
        <Banner />
        <ResponsiveView projects={projects} />
        <Work experience={work} />
        <Footer />
      </section>
    </main>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    allProjectsJson(filter: { lang: { eq: $language } }) {
      edges {
        node {
          date
          name
          tag
          repository
          description
          img {
            childImageSharp {
              id
              gatsbyImageData
            }
          }
        }
      }
    }
    allWorkJson(filter: { lang: { eq: $language } }) {
      edges {
        node {
          admission
          isCurrent
          termination
          company
          title
          description
          img {
            childImageSharp {
              id
              gatsbyImageData(transformOptions: { fit: INSIDE })
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
