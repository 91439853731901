import './style.css'
import Slide from 'react-reveal/Slide'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Banner = () => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-col items-center justify-center bg-neutral-100 h-[calc(100vh-56px)] max-w-[100vw] overflow-x-hidden'>
      <Slide bottom>
        <div className='flex flex-col'>
          <p className='font-sub pl-4 text-lg md:text-2xl text-gray-700'>
            {t('banner.welcome')}{' '}
            <span className='border-b-2 border-gradient'>
              {t('banner.underline')}
            </span>
          </p>
          <div className='w-full flex justify-center'>
            <h1
              id='logo'
              className='text-8xl md:text-9xl font-header font-extrabold z-10 bg-gradient-to-r from-brand to-auxiliary'
            >
              B<span>run</span>o
            </h1>
          </div>
          <div className='font-sub flex flex-col text-xl md:text-3xl text-gray-700 w-3/4 self-end mt-4'>
            <p className='pl-6 md:pl-0'>{t('banner.tagline')}</p>
          </div>
        </div>
      </Slide>
    </div>
  )
}
