import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Slide from 'react-reveal/Slide'
import { compareDesc } from 'date-fns'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Projects = ({ projects }) => {
  const { t } = useTranslation()

  const parsed = projects.map((p) => ({ ...p, date: new Date(p.date) }))

  parsed.sort((a, b) => compareDesc(a.date, b.date))

  return (
    <div className='py-8 sm:pl-8 max-w-fit mx-auto flex flex-col gap-4 w-full '>
      <h1 className='text-center font-header font-extrabold text-gradient bg-gradient-to-r from-brand to-auxiliary text-2xl'>
        {t('projects.title')}
      </h1>
      {parsed.map((p, i) => (
        <Slide key={p.name} bottom>
          <div
            className={`
              flex 
              flex-col-reverse
              lg:flex-row
              ${i % 2 !== 0 ? '0g:flex-row-reverse' : ''}
              pt-8 gap-8
              justify-center
              group
            `}
          >
            <div className='flex flex-col max-w-sm'>
              <h3 className='font-sub uppercase tracking-widest font-light mb-2'>
                {p.tag}
              </h3>
              <h2 className='text-2xl mb-2 font-header font-extrabold'>
                {p.name}
              </h2>
              <p className='font-text leading-relaxed'>{p.description}</p>
              <a
                href={p.repository}
                rel='noopener'
                target='_blank'
                className='self-start mt-auto pt-4 font-header font-extrabold hover:cursor-pointer hover:text-gradient hover:bg-gradient-to-r hover:from-brand hover:to-auxiliary'
              >
                Github
              </a>
            </div>
            <GatsbyImage
              loading='lazy'
              className='max-w-sm grayscale group-hover:grayscale-0 transition-all'
              alt={p.name}
              image={getImage(p.img)}
            />
          </div>
        </Slide>
      ))}
    </div>
  )
}

