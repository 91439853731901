import Slide from 'react-reveal/Slide'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { compareDesc, format } from 'date-fns'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Work = ({ experience }) => {
  const { t } = useTranslation()

  const ordered = experience.sort((a, b) =>
    compareDesc(new Date(a.admission), new Date(b.admission))
  )

  return (
    <div className='flex flex-col'>
      <div className='hidden sm:block flex-shrink-0 w-full h-1 bg-neutral-100 ' />
      <div className='flex flex-col container pb-8 sm:py-8'>
        <h1 className='text-center font-header leading-relaxed text-2xl pb-8 sm:pb-2 font-extrabold text-gradient  bg-gradient-to-r from-brand to-auxiliary'>
          {t('work.title')}
        </h1>

        {ordered.map((e) => (
          <Slide key={e.company} bottom>
            <div className='flex flex-col sm:flex-row max-w-sm sm:max-w-screen-lg mx-auto gap-4 sm:gap-8 mb-8'>
              <div>
                <GatsbyImage
                  alt={e.company}
                  image={getImage(e.img)}
                  className='w-32 flex-shrink-0'
                />
              </div>
              <div>
                <h3 className='font-sub uppercase tracking-widest font-light '>
                  {e.isCurrent
                    ? `${format(new Date(e.admission), 'MM/yyyy')} - ${t(
                        'work.today'
                      )}`
                    : `${format(new Date(e.admission), 'MM/yyyy')} - ${format(
                        new Date(e.termination),
                        'MM/yyyy'
                      )}`}
                </h3>
                <h2 className='text-2xl mb-2 font-header font-extrabold'>
                  {e.company}
                </h2>
                <h4 className='font-sub text-sm uppercase tracking-widest font-light mb-2'>
                  {e.title}
                </h4>
                <p className='font-text leading-relaxed'>{e.description}</p>
              </div>
            </div>
          </Slide>
        ))}
      </div>
    </div>
  )
}
