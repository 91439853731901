import { StaticImage } from 'gatsby-plugin-image'
import Fade from 'react-reveal/Fade'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const About = () => {
  const { t } = useTranslation()

  return (
    <Fade>
      <div className='flex flex-col bg-white py-8 gap-8'>
        <h1 className='text-center font-header leading-relaxed text-2xl font-extrabold text-gradient  bg-gradient-to-r from-brand to-auxiliary'>
          {t('about.title')}
        </h1>
        <div className='flex flex-col gap-2 items-center'>
          <div className='rounded-full overflow-hidden'>
            <div className='bg-gradient-to-r from-brand to-auxiliary'>
              <StaticImage
                className='w-48 h-48 m-1 rounded-full z-0'
                src='../../images/me.jpg'
                alt=''
                placeholder='blurred'
              />
            </div>
          </div>
          <div className='flex flex-col gap-3 text-center'>
            <h1 className='leading-tall mt-4 font-header font-extrabold text-2xl'>
              Bruno Fusieger
            </h1>
            <p className='font-text leading-relaxed'>
              {t('about.description')}
            </p>
            <div className='flex justify-center gap-3 mt-3'>
              <a
                className='cursor-pointer'
                href='https://www.linkedin.com/in/brunofusieger/'
                target='_blank'
                rel='noopener'
              >
                <svg viewBox='0 0 382 382' className='w-10'>
                  <path
                    fill='#0077b7'
                    d='M347.445 0H34.555C15.471 0 0 15.471 0 34.555v312.889C0 366.529 15.471 382 34.555 382h312.889C366.529 382 382 366.529 382 347.444V34.555C382 15.471 366.529 0 347.445 0zM118.207 329.844c0 5.554-4.502 10.056-10.056 10.056H65.345c-5.554 0-10.056-4.502-10.056-10.056V150.403c0-5.554 4.502-10.056 10.056-10.056h42.806c5.554 0 10.056 4.502 10.056 10.056v179.441zM86.748 123.432c-22.459 0-40.666-18.207-40.666-40.666S64.289 42.1 86.748 42.1s40.666 18.207 40.666 40.666-18.206 40.666-40.666 40.666zM341.91 330.654a9.247 9.247 0 0 1-9.246 9.246H286.73a9.247 9.247 0 0 1-9.246-9.246v-84.168c0-12.556 3.683-55.021-32.813-55.021-28.309 0-34.051 29.066-35.204 42.11v97.079a9.246 9.246 0 0 1-9.246 9.246h-44.426a9.247 9.247 0 0 1-9.246-9.246V149.593a9.247 9.247 0 0 1 9.246-9.246h44.426a9.247 9.247 0 0 1 9.246 9.246v15.655c10.497-15.753 26.097-27.912 59.312-27.912 73.552 0 73.131 68.716 73.131 106.472v86.846z'
                  />
                </svg>
              </a>
              <a
                className='cursor-pointer'
                href='https://github.com/bfsgr/'
                target='_blank'
                rel='noopener'
              >
                <svg viewBox='0 0 32 32' className='w-10'>
                  <path d='M16 .396c-8.839 0-16 7.167-16 16 0 7.073 4.584 13.068 10.937 15.183.803.151 1.093-.344 1.093-.772 0-.38-.009-1.385-.015-2.719-4.453.964-5.391-2.151-5.391-2.151-.729-1.844-1.781-2.339-1.781-2.339-1.448-.989.115-.968.115-.968 1.604.109 2.448 1.645 2.448 1.645 1.427 2.448 3.744 1.74 4.661 1.328.14-1.031.557-1.74 1.011-2.135-3.552-.401-7.287-1.776-7.287-7.907 0-1.751.62-3.177 1.645-4.297-.177-.401-.719-2.031.141-4.235 0 0 1.339-.427 4.4 1.641a15.436 15.436 0 0 1 4-.541c1.36.009 2.719.187 4 .541 3.043-2.068 4.381-1.641 4.381-1.641.859 2.204.317 3.833.161 4.235 1.015 1.12 1.635 2.547 1.635 4.297 0 6.145-3.74 7.5-7.296 7.891.556.479 1.077 1.464 1.077 2.959 0 2.14-.02 3.864-.02 4.385 0 .416.28.916 1.104.755 6.4-2.093 10.979-8.093 10.979-15.156 0-8.833-7.161-16-16-16z' />
                </svg>
              </a>
              <a className='cursor-pointer' href='mailto:contato@bfsgr.com.br'>
                <svg viewBox='0 0 502.1 502.1' className='w-10'>
                  <path
                    fill='#ffd15c'
                    d='M501.8 481.2c0 6.2-2 11.5-6.3 15.6a22.6 22.6 0 0 1-14.6 5.3H21.2a25 25 0 0 1-14.7-5.3 21.3 21.3 0 0 1-6.2-15.6V175h501.5v306.2z'
                  />
                  <path
                    fill='#f8b64c'
                    d='M495.5 496.8a22.6 22.6 0 0 1-14.6 5.3H21.2a25 25 0 0 1-14.7-5.3l244.5-210 244.5 210z'
                  />
                  <path
                    fill='#40596b'
                    d='m501.8 175-71 51.2-166.2 119.1a23.5 23.5 0 0 1-28.2 0l-165-119L.2 175l71-51.2L226 13.1l11.5-8.4a23.5 23.5 0 0 1 28.2 0l11.5 8.4 74.1 53.3 36.6 26 42.9 31.4 71 51.2z'
                  />
                  <path
                    fill='#f2f2f2'
                    d='M430.8 77.8v148.4L264.6 345.3a23.5 23.5 0 0 1-28.2 0l-165-119V34a21 21 0 0 1 20.8-21H365l65.9 64.8z'
                  />
                  <path
                    fill='#cdd6e0'
                    d='M366 61.1c0 9.4 7.3 16.7 16.7 16.7h48L366 13.1v48z'
                  />
                  <path
                    fill='#ff7058'
                    d='M312.7 223h16.7a55.2 55.2 0 0 1-25 26.2 92.1 92.1 0 0 1-47 10.5 103 103 0 0 1-46-9.4A61.6 61.6 0 0 1 182 223a81.4 81.4 0 0 1-9.4-38.7c0-15.6 3.1-29.2 10.4-42.8a66 66 0 0 1 29.3-29.3 95.6 95.6 0 0 1 42.8-9.4 82 82 0 0 1 36.6 8.4 51.3 51.3 0 0 1 24 23 67.9 67.9 0 0 1 8.4 32.4c0 13.5-4.2 26-12.6 37.6a47 47 0 0 1-40.7 20.9c-4.2 0-8.4-1-10.5-2.1-2-2.1-4.1-4.2-4.1-7.3a30 30 0 0 1-22 9.4 27 27 0 0 1-22-9.4 33.9 33.9 0 0 1-9.3-25.1 64 64 0 0 1 10.4-35.5 38.9 38.9 0 0 1 33.5-18.8c9.4 0 16.7 4.1 21.9 11.5l2-9.5h22L280.3 197c-1 4.1-1 6.2-1 7.3s0 2 1 3.1 1 1 2 1c2.2 0 6.4-2 10.5-5.2a43.6 43.6 0 0 0 13.6-16.7 56.1 56.1 0 0 0 5.2-22c0-13.5-5.2-25-14.6-33.4-9.4-9.4-23-13.5-40.7-13.5a71.8 71.8 0 0 0-37.7 9.4c-10.4 6.2-17.7 14.6-23 25s-7.3 22-7.3 34.5c0 11.5 3.2 22 8.4 31.4a51.8 51.8 0 0 0 24 20.9 107 107 0 0 0 69 1 62.3 62.3 0 0 0 23-16.7zm-88.8-33.4c0 7.4 1 11.5 4.1 15.7 3.2 3.2 6.3 5.2 10.5 5.2 3.1 0 6.3-1 8.4-2 2-1 4.1-3.2 6.2-5.3 3.2-3.1 5.3-8.3 7.3-14.6a58 58 0 0 0 3.2-17.8c0-6.2-1-10.4-4.2-14.6-3.1-3.1-6.3-5.2-10.5-5.2a19 19 0 0 0-12.5 5.2A28.7 28.7 0 0 0 227 172a58 58 0 0 0-3.1 17.7z'
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  )
}
