import { About } from '../About'
import { Projects } from '../Projects'
import { Work } from '../Work'

export const ResponsiveView = ({ projects }) => {
  return (
    <div className='overflow-hidden flex flex-col sm:flex-row flex-wrap sm:flex-nowrap container'>
      <div className='flex mx-auto max-w-sm sm:max-w-min xl:max-w-sm sm:pr-8'>
        <About />
      </div>
      <div className='hidden sm:block flex-shrink-0 w-1 bg-neutral-100 ' />
      <Projects projects={projects} />
    </div>
  )
}
